
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import * as Yup from "yup";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "users",

  components: {
    ErrorMessage,
    Field,
    Form,
    VPagination,
  },

  props: {},

  data() {
    const validation = Yup.object().shape({
      cmp: Yup.string().required("필수값입니다."),
      user_login_id: Yup.string().required("필수값입니다."),
      password: Yup.string().required("필수값입니다."),
      user_ty: Yup.string().required("필수값입니다."),
      user_nm: Yup.string().required("필수값입니다."),
      user_tel: Yup.string()
        .matches(/^\d{11}$/, "-를 제외한 휴대폰 번호 11자리를 입력하세요.")
        .required("필수값입니다."),
      user_email: Yup.string()
        .required("필수값입니다.")
        .email("email 형식이 아닙니다."),
      user_use_yn: Yup.boolean().required("필수값입니다."),
      bsns_licnbr: Yup.string().when("user_ty", {
        is: "UT100400",
        then: Yup.string().required("필수값입니다."),
      }),
    });

    return {
      users: [],
      userData: {},
      user: {},
      companys: {},
      searchcode: "cmp_nm",
      searchdata: "",
      status: "",
      page: 1,
      validation,
      radioBox: [{}],
      businessNumber: [{}],
    };
  },

  created() {
    base
      .getCompanyList()
      .then((res) => {
        console.log(res);
        this.companys = res.data;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
    this.getListData();
  },

  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        user_use_yn: this.status,
        page: this.page,
      };
      base
        .getUserList(param)
        .then((res) => {
          console.log(res);
          this.users = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getData(id) {
      base
        .getUser(id)
        .then((res) => {
          console.log(res);
          this.userData = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    findData() {
      const businessObject = {
        [this.searchCode]: this.businessSearch,
      };
      base
        .getBusinessNumberList(businessObject)
        .then((res) => {
          console.log(res);
          this.businessNumber = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async editData() {
      base
        .updateUser(this.userData)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("사용자 정보를 수정했습니다.");
            // this.modalopen = false;
            window.location.reload();
            this.getListData();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async submitForm() {
      this.user.bsns = this.radioBox.id;
      console.log(this.user);

      base
        .newUser(this.user)
        .then((res) => {
          console.log(res);

          if (res.status == 201) {
            alert("신규 사용자를 등록했습니다.");
            this.modalopen = false;
            window.location.reload();
            // this.getListData();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
      this.clearForm();
    },
  },

  clearForm() {
    for (const key in this.user) {
      this.user[key] = "";
    }
  },

  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
